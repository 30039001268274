import React from "react";

interface GameContextData {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GameContext = React.createContext({} as GameContextData);

interface CountdownProviderProps {
  children: React.ReactNode;
}

export const GameProvider = ({ children }: CountdownProviderProps) => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <GameContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
