import { Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import FooterBody from "./FooterBody";
import FooterTitle from "./FooterTitle";
import SocialMediaIcon from "./SocialMediaIcon";

import FacebookIcon from "../assets/img/footer/facebook-icon.svg";
import InstagramIcon from "../assets/img/footer/instagram-icon.svg";
import LinkedinIcon from "../assets/img/footer/linkedin-icon.svg";

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      minHeight: 300,
      fontFamily: "Merriweater Sans",
      paddingBottom: 100,
    },
    iconContainer: {
      paddingTop: 15,
    },
    email: {
      fontWeight: 700,
      lineHeight: 0,
      marginLeft: 10,
    },
  }),
);

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="flex-start"
      className={classes.footer}
    >
      <Grid item>
        <FooterTitle>CONTACT US</FooterTitle>
        <FooterBody>
          <div className={classes.iconContainer}>
            <SocialMediaIcon
              icon={FacebookIcon}
              url="https://www.facebook.com/brickabode/"
              alt="Brick Abode Facebook"
            />
            <SocialMediaIcon
              icon={LinkedinIcon}
              url="https://www.linkedin.com/company/brickabode/"
              alt="Brick Abode Linkedin"
            />
            <SocialMediaIcon
              icon={InstagramIcon}
              url="https://www.instagram.com/brickabode/"
              alt="Brick Abode Instagram"
            />
          </div>
          <p className={classes.email}>
            <a href="mailto:winning@brickabode.com">winning@brickabode.com</a>
          </p>
        </FooterBody>
      </Grid>
      <Grid item>
        <FooterTitle>BRICK ABODE USA</FooterTitle>
        <FooterBody>
          775 Virginia Avenue Salem, VA, United States 24153
        </FooterBody>
      </Grid>
      <Grid item>
        <FooterTitle>BRICK ABODE BRAZIL</FooterTitle>
        <FooterBody>
          55 Rua Patrício Farias, Sala 705 Itacorubi, Florianópolis, SC, Brazil
          88034-132
        </FooterBody>
      </Grid>
    </Grid>
  );
}
