import { useEffect, useState } from "react";

export default function Ellipsis() {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (dots === 3) setDots(0);
      else setDots(dots + 1);
    }, 350);
    return () => clearInterval(interval);
  }, [dots]);

  const generateOutput = () => {
    const ellipsis = [".", ".", "."];
    if (dots < ellipsis.length) ellipsis[dots] = "·";
    return ellipsis.join("");
  };

  return <>{generateOutput()}</>;
}
