import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import CursorGreenIcon from "../../assets/img/game/cursor-green.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cursor: {
      position: "absolute",
      width: 70,
      height: 70,
      left: -40,
      top: 160,
      background: `url("${CursorGreenIcon}") no-repeat center center`,
      backgroundSize: "100%",
      [theme.breakpoints.down("sm")]: {
        width: 50,
        height: 50,
        left: -30,
        top: 110,
      },
      [theme.breakpoints.down("xs")]: {
        width: 40,
        height: 40,
        left: -25,
        top: 95,
      },
    },
  }),
);

export default function CursorGreen({ ...rest }) {
  const classes = useStyles();
  return <div className={classes.cursor} {...rest} />;
}
