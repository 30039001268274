import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { GameButtonPrimary } from "./GameButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skipButtonContainer: {
      position: "fixed",
      bottom: 0,
      height: 80,
      width: 200,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 2,
      right: 20,
      [theme.breakpoints.down("xs")]: {
        right: -20,
      },
      "& button": {
        height: 42.5,
        width: "60%",
        padding: theme.spacing(2),
      },
      "& button *": {
        fontSize: "0.55rem",
        letterSpacing: 3,
      },
    },
    skipButtonPipe: {
      border: "3px solid #4120ba",
      background: "#0b1c20",
      width: 15,
      top: 25,
      height: 500,
      position: "absolute",
      boxSizing: "border-box",
    },
  }),
);

interface SkipButtonProps {
  onClick?: () => void;
}

export default function SkipButton({ onClick }: SkipButtonProps) {
  const classes = useStyles();

  return (
    <div className={classes.skipButtonContainer}>
      <div className={classes.skipButtonPipe} />
      <GameButtonPrimary onClick={onClick}>
        SK<span className="second">I</span>P INT
        <span className="first">R</span>O
      </GameButtonPrimary>
    </div>
  );
}
