import { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Shelf from "./Shelf";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gameBoardContainer: {
      display: "flex",
      justifyContent: "center",
      maxWidth: 1100,
      width: "100%",
    },
    gameBoard: {
      position: "relative",
      border: "2.47552px solid #6C43FF",
      background: "#001420",
      width: "80%",
      display: "flex",
      justifyContent: "center",
      boxShadow:
        "2.05254px 2.05254px 20.5254px #6C43FF, inset 0px 4.10508px 20.5254px #6C43FF",
      borderRadius: 8.25175,
      marginBottom: 80,
      height: 300,
      [theme.breakpoints.down("sm")]: {
        height: 200,
      },
      [theme.breakpoints.down("xs")]: {
        height: 150,
      },
    },
  }),
);

interface GameBoardContainerProps {
  children: ReactNode;
}

export default function GameBoardContainer({
  children,
}: GameBoardContainerProps) {
  const classes = useStyles();
  return (
    <div className={classes.gameBoardContainer}>
      <div className={classes.gameBoard}>
        {children}
        <Shelf />
      </div>
    </div>
  );
}
