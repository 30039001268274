import { ReactNode, useEffect, useState } from "react";
import { ButtonGroup, Button, Container, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Vimeo from "@u-wave/react-vimeo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    testimonialContainer: {
      width: "100%",
      maxWidth: 762,
      position: "absolute",
      transition: "opacity 0.25s ease-in-out",
    },
    videoContainer: {
      position: "relative",
      width: "100%",
      background: "white",
    },
    buttonsContainer: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      right: 0,
      left: 0,
      top: -55,
      "& > button": {
        color: theme.palette.primary.light,
        border: `thin solid ${theme.palette.primary.light}`,
        backgroundColor: "#ffffff",
        borderColor: theme.palette.primary.light,
        borderRadius: 7,
        height: 40,
        "& svg": { fontSize: 40 },
      },
    },
    text: {
      color: theme.palette.primary.contrastText,
      paddingTop: 35,
      paddingBottom: 300,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 100,
      },
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 0,
      },
      "& *": {
        fontFamily: "Open Sans",
        fontWeight: 300,
      },
    },
    name: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "22.26px",
    },
    role: {
      fontSize: 17,
      lineHeight: "18px",
    },
    description: {
      marginTop: 35,
      maxWidth: 714,
      fontSize: 20,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    highlight: {
      fontWeight: 700,
      background: "#ffe600",
      color: "#002f43",
    },
  }),
);

interface Testimonial {
  name: string;
  role: string;
  videoId: string | number;
  description: ReactNode;
}

export default function TestimonialContent() {
  const classes = useStyles();

  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [isVideoPaused, setIsVideoPaused] = useState(true);
  const [containerHeight, setContainerHeight] = useState(0);

  const heights: number[] = [];
  useEffect(() => {
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);
  }, []);

  const updateContainerHeight = () => {
    // Reset the array
    heights.splice(0, heights.length);

    // Get all elements
    const testimonialElements = document.getElementsByClassName(
      "testimonial-container-class",
    );

    // List all testimonial heights
    for (let t = 0; t < testimonialElements.length; t++) {
      heights.push(testimonialElements.item(t)?.clientHeight ?? 0);
    }

    // Set the height of the text container to the maximum value of the array
    setContainerHeight(Math.max(...heights));
  };

  const testimonials: Testimonial[] = [
    {
      name: "Robert Sanders Testimonial",
      role: "Chief Technology Officer of Verusen",
      videoId: "566669775",
      description: (
        <>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            One of our clients, puts it best{" "}
            <span className={classes.highlight}>
              &quot;DevConcierge™ helps you get to a level of maturity in your
              process and your operations faster and at a lower cost than you
              can with internal resources alone.&quot;
            </span>
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            It&apos;s hard enough to put a good development team together, but
            what about the team that does... everything else? Engineers who
            really know how to release software quickly and reliably, and how to
            run it when you&apos;re done, are rarer than hens&apos; teeth and
            almost as expensive. Their work isn&apos;t directly tied to your
            functionality, and getting good performance from them takes a ton of
            management attention.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            With our DevConcierge™ offering, we take all of that off your plate,
            delivering you rock-solid releases and devops, based on strong
            relationships, from a team that&apos;s done it before. Creating a
            relationship with a strong development shop in a friendly time zone
            is just icing on the cake for our customers.
          </Typography>
        </>
      ),
    },
    {
      name: "Greg Frady Testimonial",
      role: "Director of Engineering of Verusen",
      videoId: "566670928",
      description: (
        <>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            The developer experience under DevConcierge™ is great.{" "}
            <span className={classes.highlight}>
              It&apos;s pain-free. My developers are free to work on exactly
              what they should be working on, while DevConcierge™ takes care of
              all the fundamental pieces of the work.
            </span>
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            As far as our software environment goes, before it was pretty wild
            west. We were in a very early-stage startup, with very few
            engineering hires. And, to be completely honest, we just didn&apos;t
            have the bandwidth to deal with a lot of the smaller pieces of tech
            debt.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.description}
          >
            So Brick Abode came in and used their model service on some areas
            that we hadn&apos;t actually considered. We put together a plan and
            they gave us a couple of options. We picked a few that we liked,
            focused on the things that were most important to us. Then they hit
            the ground running. Within a month, they were actually part of the
            team. It feels good to be able to hand over great things and not
            have to worry about it. Lets you sleep at night.
          </Typography>
        </>
      ),
    },
  ];

  const getCurrentOpacity = (testimonialId: number): 0 | 1 =>
    testimonialId === testimonialIndex ? 1 : 0;

  const getCurrentPointerEvents = (testimonialId: number): "auto" | "none" =>
    testimonialId === testimonialIndex ? "auto" : "none";

  const isCurrentVideoPaused = (testimonialId: number): boolean =>
    testimonialIndex !== testimonialId || isVideoPaused;

  return (
    <div className={classes.container} style={{ height: containerHeight }}>
      <ButtonGroup
        disableElevation
        variant="contained"
        className={classes.buttonsContainer}
      >
        <Button
          disabled={testimonialIndex === 0}
          onClick={() => {
            setTestimonialIndex(testimonialIndex - 1);
            setIsVideoPaused(true);
          }}
        >
          <NavigateBeforeIcon />
          PREV
          {heights.join(",")}
        </Button>
        <Button
          disabled={testimonialIndex === testimonials.length - 1}
          onClick={() => {
            setTestimonialIndex(testimonialIndex + 1);
            setIsVideoPaused(true);
          }}
        >
          NEXT
          <NavigateNextIcon />
        </Button>
      </ButtonGroup>
      {testimonials.map((testimonial, index) => {
        const { name, role, videoId, description } = testimonial;

        return (
          <div
            key={name}
            className={classes.testimonialContainer}
            style={{
              opacity: getCurrentOpacity(index),
              pointerEvents: getCurrentPointerEvents(index),
            }}
          >
            <div className={classes.videoContainer}>
              <Vimeo
                video={videoId}
                responsive
                paused={isCurrentVideoPaused(index)}
                onPlay={() => {
                  setIsVideoPaused(false);
                }}
                showPortrait={false}
                showTitle={false}
                showByline={false}
              />
            </div>
            <Container
              className={`${classes.text} testimonial-container-class`}
            >
              <Typography variant="h1" gutterBottom className={classes.name}>
                {name}
              </Typography>
              <Typography variant="h2" gutterBottom className={classes.role}>
                {role}
              </Typography>
              {description}
            </Container>
          </div>
        );
      })}
    </div>
  );
}
