import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import VerticalLine from "../assets/img/footer/vertical-line.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerBody: {
      color: theme.palette.primary.contrastText,
      fontWeight: 400,
      fontSize: 18,
      fontFamily: "Merriweather Sans",
      paddingTop: 50,
      width: 300,
    },
    verticalLine: {
      background: `url("${VerticalLine}") no-repeat`,
      minHeight: 70,
      maxWidth: 250,
      paddingLeft: 15,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& div": {
        marginTop: -7,
      },
    },
  }),
);

export default function FooterTitle({
  children,
}: {
  children: JSX.Element | JSX.Element[] | string;
}) {
  const classes = useStyles();

  return (
    <div className={classes.footerBody}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <div className={classes.verticalLine}>
            <div>{children}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
