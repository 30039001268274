import { ReactNode, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Scale from "./Scale";

import BoxDevConciergeGame from "../../assets/img/game/box-dev-concierge.svg";
import BoxTheCTOGame from "../../assets/img/game/box-the-cto-game.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxes: {
      boxSizing: "border-box",
      width: "65%",
      position: "relative",
      zIndex: 1,
      paddingBottom: 35,
      transition: "opacity 0.25s ease-in",
      opacity: 0,
      "@media (max-width:1200px)": {
        width: "70%",
      },
      "@media (max-width:1100px)": {
        width: "73%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "63%",
        paddingBottom: 0,
      },
      "@media (max-width:800px)": {
        width: "70%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "70%",
      },
      "@media (max-width:450px)": {
        width: "78%",
        "& > div:first-child": {
          left: "5%",
        },
        "& > div:last-child": {
          left: "35%",
        },
      },
    },
    boxCTOContainer: {
      position: "absolute",
      width: "42%",
      left: "0",
      height: "130%",
    },
    boxCTO: {
      background: `url("${BoxTheCTOGame}") no-repeat bottom center`,
      backgroundSize: "100%",
      width: "100%",
      height: "100%",
    },
    boxDevConciergeContainer: {
      position: "absolute",
      width: "50%",
      height: "150%",
      left: "30%",
    },
    boxDevConcierge: {
      background: `url("${BoxDevConciergeGame}") no-repeat bottom center`,
      backgroundSize: "100%",
      width: "100%",
      height: "100%",
    },
  }),
);

interface BoxesContainerProps {
  children: ReactNode;
}

export function BoxesContainer({ children }: BoxesContainerProps) {
  const classes = useStyles();
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <div className={classes.boxes} style={{ opacity }}>
      {children}
    </div>
  );
}

interface BoxDevConciergeProps {
  delay?: number;
}

export function BoxCTO({ delay = 0 }: BoxDevConciergeProps) {
  const classes = useStyles();

  return (
    <div className={classes.boxCTOContainer}>
      {delay ? (
        <Scale delay={delay}>
          <div className={classes.boxCTO} />
        </Scale>
      ) : (
        <div className={classes.boxCTO} />
      )}
    </div>
  );
}

export function BoxDevConcierge({ delay = 0 }: BoxDevConciergeProps) {
  const classes = useStyles();

  return (
    <div className={classes.boxDevConciergeContainer}>
      {delay ? (
        <Scale delay={delay}>
          <div className={classes.boxDevConcierge} />
        </Scale>
      ) : (
        <div className={classes.boxDevConcierge} />
      )}
    </div>
  );
}
