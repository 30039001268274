import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import HeaderCards from "../components/HeaderCards";
import ScrollToBookMeeting from "../components/ScrollToBookMeeting";

import BGPoints from "../assets/img/header/bg-points-header.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: `url("${BGPoints}") no-repeat -15px 0, linear-gradient(179.34deg, #013247 0.55%, #1E1C81 57.2%, #4120BA 99.43%)`,
      backgroundPosition: "bottom left",
      paddingTop: 50,
    },
    title: {
      color: theme.palette.primary.contrastText,
      margin: "180px auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        margin: "50px auto",
      },
      "& > h1": {
        fontFamily: "Open Sans",
        fontWeight: 700,
        maxWidth: 1052,
        fontSize: 38,
        [theme.breakpoints.down("sm")]: {
          fontSize: 30,
        },
      },
    },
    mainSubtitle: {
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 40,
      color: "#FFFFFF",
      textAlign: "center",
      marginBottom: 25,
    },
    subtitle: {
      color: theme.palette.primary.contrastText,
      fontFamily: "Open Sans",
      maxWidth: 993,
      fontSize: 22,
      lineHeight: "30px",
      textAlign: "center",
      margin: "15px auto",
      marginBottom: 50,
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },
    gridContainer: {
      width: "100%",
      margin: "auto",
    },
    button: {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
      fontSize: 18,
      marginBottom: 45,
      width: 260,
      height: 53,
      "&:hover": {
        background: theme.palette.primary.main,
      },
      "&:active": {
        background: theme.palette.primary.light,
      },
    },
  }),
);

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Container>
        <div className={classes.title}>
          <Typography variant="h5" component="h1" gutterBottom>
            How long have you been hoping to do DevOps yourself, but
            haven&apos;t done it yourself?
          </Typography>
        </div>
        <Typography variant="h4" gutterBottom className={classes.mainSubtitle}>
          Meet Developer Concierge™
        </Typography>
        <Typography
          variant="subtitle1"
          component="h2"
          gutterBottom
          className={classes.subtitle}
        >
          If you’re not experienced at building and running a DevOps team, then
          it can be a daunting challenge. Our DevConcierge solution is based on
          4 pillars to allow your tech team to focus on what really matters -
          your product.
        </Typography>
        <HeaderCards />
      </Container>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item>
          <ScrollToBookMeeting>
            <Button
              color="inherit"
              variant="outlined"
              className={classes.button}
            >
              Let&apos;s get together
            </Button>
          </ScrollToBookMeeting>
        </Grid>
      </Grid>
    </div>
  );
}
