import TagManager from "react-gtm-module";

export const startGTM = () => {
  const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "";

  if (process.env.NODE_ENV === "production" && !!GTM_ID) {
    const tagManagerArgs = {
      gtmId: GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }
};
