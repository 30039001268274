import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "Cano, sans-serif",
      letterSpacing: 5,
      transition: "font-size 0.15s ease-in-out",
      color: "#fff",
      textAlign: "center",
      marginTop: theme.spacing(2),
      lineHeight: 1,
      fontSize: "2.8rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    subtitle: {
      fontFamily: "Cano, sans-serif",
      fontSize: "1.5rem",
      color: "#fff",
      textAlign: "center",
      marginTop: 5,
      transition: "font-size 0.15s ease-in-out",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.25rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  }),
);

export default function GameTitle() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        THE CTO GAME IS HARD TO PLAY
      </Typography>
      <Typography variant="h2" className={classes.subtitle}>
        We make it easier.
      </Typography>
    </>
  );
}
