import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import ScrollToBookMeeting from "./ScrollToBookMeeting";

import BALogo from "../assets/img/logo-BA-white.svg";
import DevConciergeLogo from "../assets/img/logo-dev-concierge.svg";
import BALogoSmall from "../assets/img/navbar/logo-BA-small.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navbar: {
      minHeight: 130,
      borderBottomWidth: 13,
      borderBottomColor: theme.palette.primary.light,
      borderBottomStyle: "solid",
    },
    toolbar: {
      minHeight: 130,
    },
    item: {
      margin: 10,
      display: "flex",
      justifyContent: "center",
    },
    baLogo: {
      width: "100%",
      maxWidth: 263,
      [theme.breakpoints.down("sm")]: {
        width: 21,
      },
    },
    devConciergeContainer: {
      [theme.breakpoints.down("sm")]: {
        order: 1,
      },
    },
    devConciergeLogo: {
      width: "100%",
      maxWidth: 323,
    },
    button: {
      height: 53,
      borderRadius: 10,
      fontWeight: 700,
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10.18,
        height: 30,
      },
      "&:hover": {
        background: theme.palette.primary.main,
      },
      "&:active": {
        background: theme.palette.primary.light,
      },
    },
  }),
);

export default function Navbar() {
  const classes = useStyles();

  const sm = useMediaQuery((theme: any): any => theme.breakpoints.down("sm"));

  return (
    <AppBar position="static" className={classes.navbar}>
      <Toolbar className={classes.toolbar}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item className={classes.item} sm>
            <a
              href="https://brickabode.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={sm ? BALogoSmall : BALogo}
                alt="Brick Abode Logo"
                className={classes.baLogo}
              />
            </a>
          </Grid>
          <Grid
            item
            className={`${classes.item} ${classes.devConciergeContainer}`}
            sm={12}
            md
          >
            <a href="/">
              <img
                src={DevConciergeLogo}
                alt="DevConcierge Logo"
                className={classes.devConciergeLogo}
              />
            </a>
          </Grid>
          <Grid item className={classes.item} sm>
            <ScrollToBookMeeting>
              <Button
                color="inherit"
                variant="outlined"
                className={classes.button}
                size="large"
              >
                Let&apos;s get together
              </Button>
            </ScrollToBookMeeting>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
