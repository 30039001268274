import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import BALogo from "../../assets/img/logo-BA-white.svg";
import DevConciergeLogo from "../../assets/img/logo-dev-concierge.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      maxHeight: 100,
      paddingTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
      maxWidth: 1100,
      margin: "auto",
      "& img": {
        transition: "max-width 0.15s linear",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(8),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: 50,
      },
    },
    devConciergeLogo: {
      maxWidth: 250,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 200,
      },
    },
    BALogo: {
      maxWidth: 196.25,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 200,
      },
    },
  }),
);

export default function Logos() {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <Grid container justify="space-evenly" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
          <img
            src={DevConciergeLogo}
            alt="DevConcierge Logo"
            className={classes.devConciergeLogo}
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
          <img src={BALogo} alt="Brick Abode Logo" className={classes.BALogo} />
        </Grid>
      </Grid>
    </div>
  );
}
