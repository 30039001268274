export default function GoogleCalendarIframe() {
  return (
    <iframe
      src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3pE7Z-DjEJDmOEQog7FYxQLSRHW4JA70uS4iEy1NHF26n3iL50o8tOiut54Ic9SHufNHtb8W7c?gv=true"
      style={{ border: "0" }}
      width="100%"
      height="1200"
      title="Google Calendar"
    ></iframe>
  );
}
