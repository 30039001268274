import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#6C43FF",
      main: "#4120BA",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#E5E5E5",
      main: "#FFE600",
      dark: "#4F4E4E",
      contrastText: "#FFE600",
    },
  },
});

export default theme;
