import { Link } from "react-scroll";

export default function ScrollToBookMeeting({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <Link
      activeClass="active"
      to="bookMeeting"
      spy={true}
      smooth={true}
      duration={850}
      offset={-75}
    >
      {children}
    </Link>
  );
}
