import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    iconContainer: {
      marginLeft: 5,
      marginRight: 5,
    },
    icon: {
      width: 29,
      height: 29,
    },
  }),
);

export default function SocialMediaIcon({
  icon,
  url,
  alt,
}: {
  icon: string;
  url: string;
  alt: string;
}) {
  const classes = useStyles();
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.iconContainer}
    >
      <img src={icon} alt={alt} className={classes.icon} />
    </a>
  );
}
