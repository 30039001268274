import { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { openPopupWidget, CalendlyEventListener } from "react-calendly";
import { Element } from "react-scroll";

import ArrowRight from "../assets/img/book-meeting/arrow-right.svg";
import ArrowRightDisabled from "../assets/img/book-meeting/arrow-right-disabled.svg";
import ArrowRightDisabledXS from "../assets/img/book-meeting/arrow-right-disabled-xs.svg";
import ArrowRightXS from "../assets/img/book-meeting/arrow-right-xs.svg";
import DateTime from "../assets/img/book-meeting/datetime.svg";
import Details from "../assets/img/book-meeting/details.svg";
import Confirm from "../assets/img/book-meeting/confirm.svg";
import GoogleCalendarIframe from "../components/GoogleCalendarIframe";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background:
        "linear-gradient(179.34deg, #4120BA 0.55%, #1E1C81 36.8%, #013247 86.25%)",
    },
    main: {
      background: "#FFFFFF",
      border: "0.5px solid #4120BA",
      borderRadius: 8,
      position: "relative",
      top: -100,
      paddingTop: 50,
      paddingBottom: 50,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 0,
      },
    },
    title: {
      color: "#4F4E4E",
      fontSize: 24,
      maxWidth: 510,
      margin: "15px auto",
      textAlign: "center",
      fontFamily: "Merriweather Sans",
      lineHeight: "30px",
      wordSpacing: 5,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        maxWidth: 300,
      },
    },
    iconContainer: {
      marginTop: 35,
      marginBottom: 35,
      "& > div": {
        padding: 10,
      },
    },
    icon: {
      width: 43,
      height: 45,
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: "center !important",
    },
    iconDescription: {
      fontFamily: "Merriweather Sans",
      fontWeight: 700,
      textAlign: "center",
      color: "#4F4E4E",
      fontSize: 16,
      marginTop: 1,
    },
    arrow: {
      width: 120,
      height: 25,
      marginTop: -25,
      background: `url("${ArrowRight}") center no-repeat`,
    },
    arrowDisabled: {
      width: 120,
      height: 25,
      marginTop: -25,
      background: `url("${ArrowRightDisabled}") center no-repeat`,
    },
    arrowXS: {
      width: 20,
      height: 25,
      marginTop: -25,
      background: `url("${ArrowRightXS}") center no-repeat`,
    },
    arrowDisabledXS: {
      width: 20,
      height: 25,
      marginTop: -25,
      background: `url("${ArrowRightDisabledXS}") center no-repeat`,
    },
    calendly: {
      position: "relative",
      minHeight: 650,
      "& > div": {
        background: "#ffffff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        borderRadius: 10,
        width: "110%",
        position: "absolute",
        right: "50%",
        transform: "translateX(50%)",
        [theme.breakpoints.down("md")]: {
          width: "105%",
        },
      },
    },
    buttonContainer: {
      marginTop: 25,
      position: "relative",
    },
    button: {
      backgroundColor: "#FFE600",
      position: "absolute",
      width: 250,
      height: 70,
      top: 0,
      left: 0,
      transform: "translate(-50%, -50%)",
      border: `2px solid ${theme.palette.secondary.main}`,
      "& *": {
        color: "#002F43",
        fontFamily: "Merriweather Sans",
        fontWeight: 700,
        fontSize: 23,
        wordSpacing: 3,
      },
      "&:hover": {
        border: "2px solid #002F43",
        backgroundColor: theme.palette.secondary.main,
      },
      "&:active": {
        background: "#002F43",
        border: "2px solid #002F43",
        "& *": {
          color: theme.palette.primary.contrastText,
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: 200,
        height: 50,
        "& *": {
          fontSize: 20,
        },
      },
    },
  }),
);

export default function ScheduleMeeting({
  children,
}: {
  children: JSX.Element;
}) {
  const classes = useStyles();

  const [details, setDetails] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);

  const sm = useMediaQuery((theme: any): any => theme.breakpoints.down("sm"));

  const dateAndTimeSelected = () => {
    setDetails(true);
  };

  const eventScheduled = () => {
    setConfirm(true);
  };

  return (
    <div className={classes.container}>
      <CalendlyEventListener
        onDateAndTimeSelected={dateAndTimeSelected}
        onEventScheduled={eventScheduled}
      />
      <Container maxWidth="md">
        <div className={classes.main}>
          <Element name="bookMeeting" />
          <Typography variant="h1" className={classes.title}>
            Book a meeting with our IT Business Expert <br />
            <strong>Todd Lewis</strong>
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.iconContainer}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <div
                  className={classes.icon}
                  style={{ background: `url("${DateTime}")` }}
                />
                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.iconDescription}
                >
                  Date & Time
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <div className={sm ? classes.arrowXS : classes.arrow} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <div
                  className={classes.icon}
                  style={{
                    mask: `url("${Details}")`,
                    WebkitMask: `url("${Details}")`,
                    backgroundColor: details ? "#4F4E4E" : "#BDBDBD",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.iconDescription}
                  style={{ color: details ? "#4F4E4E" : "#BDBDBD" }}
                >
                  Details
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <div
                className={
                  sm
                    ? confirm
                      ? classes.arrowXS
                      : classes.arrowDisabledXS
                    : confirm
                    ? classes.arrow
                    : classes.arrowDisabled
                }
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <div
                  className={classes.icon}
                  style={{
                    mask: `url("${Confirm}")`,
                    WebkitMask: `url("${Confirm}")`,
                    backgroundColor: confirm ? "#4F4E4E" : "#BDBDBD",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  component="span"
                  className={classes.iconDescription}
                  style={{ color: confirm ? "#4F4E4E" : "#BDBDBD" }}
                >
                  Confirm
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {sm ? (
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <div className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    size="large"
                    type="submit"
                    onClick={() => {
                      openPopupWidget({
                        url: "https://calendly.com/tlewis-ba/meeting",
                        pageSettings: {
                          hideEventTypeDetails: true,
                        },
                      });
                    }}
                  >
                    Schedule here
                  </Button>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.calendly}>
              <GoogleCalendarIframe />
            </div>
          )}
        </div>
      </Container>
      <Container>{children}</Container>
    </div>
  );
}
