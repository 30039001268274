import { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import ArrowBottom from "../assets/img/service-model/infographic-arrow-bottom.svg";
import ArrowLeft from "../assets/img/service-model/infographic-arrow-left.svg";
import ArrowRight from "../assets/img/service-model/infographic-arrow-right.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: "#FFFFFF",
      borderRadius: 15,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
      width: 312,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      position: "relative",
      "& *": {
        fontFamily: "Open Sans",
      },
    },
    iconContainer: {
      position: "absolute",
      top: -95,
      left: 0,
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      width: 167,
      height: 160,
    },
    title: {
      fontSize: 16.45,
      marginTop: 80,
      marginBottom: theme.spacing(3),
      color: "#002F43",
      fontFamily: "Merriweather Sans",
      fontStyle: "italic",
      fontWeight: 700,
      textAlign: "center",
    },
    text: {
      color: theme.palette.secondary.dark,
      fontSize: 14,
      textAlign: "center",
      marginBottom: theme.spacing(4),
      fontFamily: "Open Sans, sans-serif",
      "& p": {
        lineHeight: "18px",
        fontSize: 14,
        margin: theme.spacing(1),
      },
    },
    arrowBottom: {
      position: "absolute",
      width: 46,
      height: 16,
      bottom: -18,
      left: "45%",
      background: `url("${ArrowBottom}")`,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    arrowLeft: {
      position: "absolute",
      width: 16,
      height: 46,
      left: -17,
      top: "10%",
      background: `url("${ArrowLeft}")`,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    arrowRight: {
      position: "absolute",
      width: 16,
      height: 46,
      right: -17.5,
      bottom: "40%",
      background: `url("${ArrowRight}")`,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  }),
);

export default function ServiceModelCard({
  icon,
  title,
  color,
  content,
  arrowBottom,
  arrowLeft,
  arrowRight,
}: {
  icon?: string;
  title?: string;
  color: string;
  content: ReactNode | string;
  arrowBottom?: boolean;
  arrowLeft?: boolean;
  arrowRight?: boolean;
}) {
  const classes = useStyles();

  const arrowBottomElement = (): ReactNode => {
    return arrowBottom && <div className={classes.arrowBottom} />;
  };

  const arrowLeftElement = (): ReactNode => {
    return arrowLeft && <div className={classes.arrowLeft} />;
  };

  const arrowRightElement = (): ReactNode => {
    return arrowRight && <div className={classes.arrowRight} />;
  };

  return (
    <div className={classes.card} style={{ border: `2px solid ${color}` }}>
      {arrowBottomElement()}
      {arrowLeftElement()}
      {arrowRightElement()}
      <div>
        {icon && (
          <div className={classes.iconContainer}>
            <div
              className={classes.icon}
              style={{ background: `url("${icon}") no-repeat` }}
            />
          </div>
        )}
        {title && (
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            {title}
          </Typography>
        )}
        <div className={classes.text} style={{ marginTop: !!title ? 0 : 35 }}>
          {content}
        </div>
      </div>
    </div>
  );
}
