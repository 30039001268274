import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      background:
        "linear-gradient(179.34deg, #013247 9.82%, #1E1C81 57.2%, #4120BA 99.43%)",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      height: 31,
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        alignItens: "flex-start",
        justifyContent: "flex-start",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    copyright: {
      color: "#fff",
      textAlign: "center",
      fontSize: 11,
      fontFamily: "'Neo Sans', sans-serif",
      letterSpacing: 3.71,
      fontWeight: 800,
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        width: 250,
        margin: theme.spacing(2),
      },
    },
  }),
);

export default function Footer({ ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.footer} {...rest}>
      <Typography variant="body1" className={classes.copyright}>
        2021 © All rights reserved to Brick Abode Corporation
      </Typography>
    </div>
  );
}
