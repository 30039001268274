import YouTube, { Options as YouTubeOptions } from "react-youtube";

interface GameVideoProps {
  onEnd?: () => void;
}

export default function GameVideo({ onEnd }: GameVideoProps) {
  const opts: YouTubeOptions = {
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return <YouTube videoId="8KyJe2gibSk" opts={opts} onEnd={onEnd} />;
}
