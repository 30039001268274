import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    shelf: {
      background:
        "linear-gradient(178.82deg, #013247 -50.82%, #1E1C81 31.73%, #4120BA 105.31%)",
      width: "108%",
      height: 62,
      position: "absolute",
      bottom: -52,
      transform: "perspective(10px) rotateX(1.5deg)",
      borderRadius: "0 0 10px 10px",
      borderBottom: "2px solid #01243a",
      boxSizing: "border-box",
    },
  }),
);

export default function Shelf() {
  const classes = useStyles();
  return <div className={classes.shelf} />;
}
