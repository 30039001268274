import { useContext, useEffect, useRef, useState } from "react";
import { Button, Grid, Grow, Icon, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import ScrollToBookMeeting from "../components/ScrollToBookMeeting";
import Card from "../components/ServiceModelCard";
import { GameContext } from "../contexts/GameContext";
import useOnScreen from "../hooks/useOnScreen";

import DevConciergeLogo from "../assets/img/service-model/logo-dev-concierge-2.svg";
import PlanningIcon from "../assets/img/service-model/planning-icon.svg";
import RegularServiceIcon from "../assets/img/service-model/regular-service-icon.svg";
import ScorecardIcon from "../assets/img/service-model/scorecard-icon.svg";
import ServiceStartupIcon from "../assets/img/service-model/service-startup-icon.svg";
import ShutdownIcon from "../assets/img/service-model/shutdown-icon.svg";
import StartupEvaluationIcon from "../assets/img/service-model/startup-evaluation-icon.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      "& *": { fontFamily: "Open Sans", color: theme.palette.secondary.dark },
    },
    logoContainer: {
      padding: `0 ${theme.spacing(4)}px`,
    },
    logo: {
      width: "100%",
      maxWidth: 426,
      marginTop: 150,
      marginBottom: 100,
    },
    title: {
      color: theme.palette.primary.light,
      fontWeight: 700,
      fontSize: 30,
      textAlign: "center",
      maxWidth: 645,
    },
    subtitle: {
      textAlign: "center",
      margin: "auto",
      marginBottom: 50,
      fontSize: 20,
      maxWidth: 645,
    },
    cardContainer: {
      maxWidth: "100%",
      margin: "auto",
      "& > div": {
        marginTop: 100,
        display: "flex",
        justifyContent: "center",
      },
    },
    cardInfo: {
      maxWidth: 332,
      height: 230,
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& *": {
        color: "#30C2FF",
      },
      "& > span": {
        fontSize: 45,
      },
      "& p": {
        fontSize: 15.3,
        fontFamily: "Merriweather Sans",
        fontWeight: "normal",
        maxWidth: 255,
        textAlign: "center",
      },
    },
    button: {
      backgroundColor: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.secondary.main}`,
      marginTop: 50,
      marginBottom: 50,
      fontSize: 18,
      width: "100%",
      maxWidth: 260,
      height: 53,
      "& *": {
        color: "#002F43",
        fontFamily: "Merriweather Sans",
        fontWeight: 700,
      },
      "&:hover": {
        border: "2px solid #002F43",
        backgroundColor: theme.palette.secondary.main,
      },
      "&:active": {
        background: "#002F43",
        border: "2px solid #002F43",
        "& *": {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  }),
);

export default function ServiceModel() {
  const classes = useStyles();

  const { isOpen } = useContext(GameContext);

  const cardsRef = useRef(null);
  const isVisible = useOnScreen(cardsRef);

  const [fade, setFade] = useState(false);

  useEffect(() => {
    !isOpen && isVisible && setFade(true);
  }, [isOpen, isVisible]);

  return (
    <div className={classes.container}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <div className={classes.logoContainer}>
            <img
              className={classes.logo}
              src={DevConciergeLogo}
              alt="DevConcierge logo"
            />
          </div>
        </Grid>
        <Grid item>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Service Model
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.subtitle}
          >
            The DevConcierge service model is designed to prevent failures in
            the first place. We prevent productivity-killing emergencies through
            our aggressively proactive posture.
          </Typography>
        </Grid>
      </Grid>

      <div ref={cardsRef}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={6}
          className={classes.cardContainer}
          style={{ width: 800 }}
        >
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 1000 } : {})}
          >
            <Grid item>
              <Card
                title="Planning"
                icon={PlanningIcon}
                color="#FFE600"
                content={
                  <>
                    <p>Meet with stakeholders</p>
                    <p>Use service model to evaluate needs</p>
                    <p>Discuss initial task list</p>
                    <p>Reach Consensus</p>
                    <p>Report & Plan</p>
                  </>
                }
                arrowRight
              />
            </Grid>
          </Grow>
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 1500 } : {})}
          >
            <Grid item>
              <Card
                title="Service Startup"
                icon={ServiceStartupIcon}
                color="#4120BA"
                content={
                  <>
                    <p>Exercise is timebound: 1-2 months</p>
                    <p>Work on initial deliverables</p>
                    <p>
                      Take on recurring responsibilities: nightly builds,
                      releases, etc
                    </p>
                    <p>Begin weekly reporting+meeting cycle</p>
                  </>
                }
                arrowRight
              />
            </Grid>
          </Grow>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={6}
          className={classes.cardContainer}
        >
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 1000 } : {})}
          >
            <Grid item>
              <Card
                title="Startup Evaluation"
                icon={StartupEvaluationIcon}
                color="#6C43FF"
                content={
                  <>
                    <p>Done with stakeholders</p>
                    <p>Written report generated</p>
                    <p>Meeting to review</p>
                    <p>Agree ongoing responsibilities</p>
                  </>
                }
                arrowRight
                arrowBottom
              />
            </Grid>
          </Grow>
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 1500 } : {})}
          >
            <Grid item>
              <Card
                title="Regular Service"
                icon={RegularServiceIcon}
                color="#40DBC1"
                content={
                  <>
                    <p>
                      Create great experience for developers (local builds,
                      branch promotion, etc)
                    </p>
                    <p>Twice-daily status updates</p>
                    <p>Weekly written reports</p>
                    <p>Written post-mortem release reports</p>
                    <p>Weekly review meeting</p>
                  </>
                }
                arrowBottom
                arrowRight
              />
            </Grid>
          </Grow>
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 2000 } : {})}
          >
            <Grid item>
              <Card
                title="Quarterly Evaluation & Scorecard"
                icon={ScorecardIcon}
                color="#38E867"
                content={
                  <>
                    <p>Evaluate performance & agree corrections</p>
                    <p>Add new service areas</p>
                    <p>De-prioritize mature service areas</p>
                  </>
                }
                arrowLeft
              />
            </Grid>
          </Grow>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={6}
          className={classes.cardContainer}
          style={{ width: 800 }}
        >
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 1000 } : {})}
          >
            <Grid item>
              <Card
                title="Service Shutdown"
                icon={ShutdownIcon}
                color="#30C2FF"
                content={
                  <>
                    <p>
                      Assemble full documentation: Initial report, weeklies,
                      post-mortems, final
                    </p>
                    <p>Deliver final written service report</p>
                    <p>10 man-hours of free post-service support for 90 days</p>
                  </>
                }
              />
            </Grid>
          </Grow>
          <Grow
            in={fade}
            style={{ transformOrigin: "0 0 0" }}
            {...(fade ? { timeout: 1500 } : {})}
          >
            <Grid item>
              <Card
                color="#30C2FF"
                content={
                  <div className={classes.cardInfo}>
                    <Typography variant="h3">
                      <Icon component={InfoOutlinedIcon} fontSize="inherit" />
                    </Typography>
                    <p>
                      The DevConcierge experience is built so that anyone else
                      can walk into the project and pick up right where we left
                      off with no issues.
                    </p>
                  </div>
                }
              />
            </Grid>
          </Grow>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid item>
            <ScrollToBookMeeting>
              <Button variant="contained" className={classes.button}>
                Let&apos;s get together
              </Button>
            </ScrollToBookMeeting>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
