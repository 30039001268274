import { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Shelf from "./Shelf";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoContainer: {
      flex: 1,
    },
    video: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      height: 335,
      background: "rgba(0, 0, 0, 0.8)",
      borderRadius: "8px 8px 0 0",
      backdropFilter: "blur(5px)",
      WebkitBackdropFilter: "blur(5px)",
      width: "100%",
      "& div:first-child": {
        position: "absolute",
        top: -15,
        left: 0,
        right: 0,
        maxHeight: 500,
        maxWidth: 600,
        margin: "auto",
        zIndex: 1,
      },
      [theme.breakpoints.down("sm")]: {
        height: 250,
        "& *": {
          maxHeight: "275px",
        },
      },
    },
  }),
);

interface GameVideoContainerProps {
  children: ReactNode;
}

export default function GameVideoContainer({
  children,
}: GameVideoContainerProps) {
  const classes = useStyles();
  return (
    <div className={classes.videoContainer}>
      <div className={classes.video}>
        {children}
        <Shelf />
      </div>
    </div>
  );
}
