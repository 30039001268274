import { ThemeProvider } from "@material-ui/core/styles";

import Navbar from "./components/Navbar";
import Header from "./views/Header";
import ServiceModel from "./views/ServiceModel";
import Testimonial from "./views/Testimonial";
import BookMeeting from "./views/BookMeeting";
import Footer from "./components/Footer";
import Game from "./views/Game";
import Fade from "./components/Fade";
import theme from "./theme";

import "./css/main.css";
import { GameProvider } from "./contexts/GameContext";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <GameProvider>
        <Game />
        <Header />
        <ServiceModel />
      </GameProvider>
      <Testimonial />
      <BookMeeting>
        <Footer />
      </BookMeeting>
      <Fade />
    </ThemeProvider>
  );
}
