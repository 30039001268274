import React from "react";
import { Grow } from "@material-ui/core";

interface ScaleProps {
  children: React.ReactElement;
  delay: number;
}

export default function Scale({ children, delay }: ScaleProps) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, delay);
  }, []);

  return (
    <div style={{ opacity: 1, width: "100%", height: "100%" }}>
      <Grow in={show} timeout={1000}>
        {children}
      </Grow>
    </div>
  );
}
