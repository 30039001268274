import React, { ReactNode } from "react";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: "#000E1C",
      boxShadow: "0 0 10px 5px rgba(65, 32, 186, 0.5)",
      borderRadius: 11,
      border: "3px solid #4120ba",
      color: "rgba(255, 255, 255, 0.8)",
      boxSizing: "border-box",
      width: "100%",
      cursor: "pointer",
      "& *": {
        fontFamily: "Cano, sans-serif",
        fontWeight: 400,
        letterSpacing: 8.89554,
        textShadow: "0.849354px 0.849354px 4.24677px rgba(255, 255, 255, 0.6)",
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
          fontSize: 10,
        },
      },
      "&:hover, &:focus": {
        background: "#000E1C",
        boxShadow: "0 0 10px 10px rgba(65, 32, 186, 0.5)",
        animation: "$buttonBorderFlicker 1.5s linear forwards",
        "&::before": {
          content: "'>>'",
          position: "absolute",
          left: -65,
          top: 1,
          fontSize: "1.5rem",
          letterSpacing: -10,
          fontFamily: "'Press Start 2P', cursive",
          animation: `$leftArrow 1s ${theme.transitions.easing.sharp} infinite`,
        },
        "&::after": {
          content: "'<<'",
          position: "absolute",
          right: -40,
          top: 1,
          fontSize: "1.5rem",
          letterSpacing: -10,
          fontFamily: "'Press Start 2P', cursive",
          animation: `$rightArrow 1s ${theme.transitions.easing.sharp} infinite`,
        },
        "& *": {
          animation: "$buttonTextFlicker 1.5s linear forwards",
        },
        "& .first": {
          animation: "$buttonLetterFlicker 2s linear infinite",
        },
        "& .second": {
          animation: "$buttonLetterFlicker 2.5s linear infinite",
        },
      },
    },
    buttonMeetDevConcierge: {
      background: "linear-gradient(180deg, #FFE600 0%, #FF8A00 100%)",
      boxShadow: "0px 2.96518px 2.96518px rgba(0, 0, 0, 0.25)",
      borderRadius: 11,
      border: "none",
      color: "rgba(255, 255, 255, 0.8)",
      "& *": {
        fontFamily: "Cano, sans-serif",
        fontWeight: 700,
        textIndent: 9,
        letterSpacing: 9,
        color: "#002F43",
        textShadow: "0px 3.91041px 3.91041px rgba(0, 0, 0, 0.25)",
        fontSize: 14,
        [theme.breakpoints.down("xs")]: {
          fontSize: 10,
        },
      },
      "&:hover, &:focus": {
        background: "linear-gradient(180deg, #FFE600 0%, #FF8A00 100%)",
        boxShadow: "0 0 10px 10px rgba(65, 32, 186, 0.5)",
        "&::before": {
          content: "'>>'",
          position: "absolute",
          left: -65,
          top: 1,
          fontSize: "1.5rem",
          letterSpacing: -10,
          fontFamily: "'Press Start 2P', cursive",
          animation: `$leftArrow 1s ${theme.transitions.easing.sharp} infinite`,
        },
        "&::after": {
          content: "'<<'",
          position: "absolute",
          right: -40,
          top: 1,
          fontSize: "1.5rem",
          letterSpacing: -10,
          fontFamily: "'Press Start 2P', cursive",
          animation: `$rightArrow 1s ${theme.transitions.easing.sharp} infinite`,
        },
      },
    },

    "@keyframes buttonTextFlicker": {
      "2%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "3%": {
        color: "rgb(255, 255, 255)",
        textShadow: "none",
      },
      "6%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "9%": {
        color: "rgb(255, 255, 255)",
        textShadow: "none",
      },
      "11%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "14%": {
        color: "rgb(255, 255, 255)",
        textShadow: "none",
      },
      "18%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "32%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "33%": {
        color: "rgb(255, 255, 255)",
        textShadow: "none",
      },
      "37%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "39%": {
        color: "rgb(255, 255, 255)",
        textShadow: "none",
      },
      "43%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "46%": {
        color: "rgb(255, 255, 255)",
        textShadow: "none",
      },
      "47%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "100%": {
        color: "rgb(255, 255, 255)",
        textShadow: "0 0 15px rgb(255, 255, 255)",
      },
    },
    "@keyframes buttonLetterFlicker": {
      "2%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "3%": {
        color: "rgba(255, 255, 255, 0.5)",
        textShadow: "none",
      },
      "6%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "9%": {
        color: "rgba(255, 255, 255, 0.5)",
        textShadow: "none",
      },
      "11%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "14%": {
        color: "rgba(255, 255, 255, 0.5)",
        textShadow: "none",
      },
      "18%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "32%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "33%": {
        color: "rgba(255, 255, 255, 0.5)",
        textShadow: "none",
      },
      "37%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "39%": {
        color: "rgba(255, 255, 255, 0.5)",
        textShadow: "none",
      },
      "40%": {
        color: "rgb(36, 75, 83)",
        textShadow: "0 0 15px rgb(36, 75, 83)",
      },
      "100%": {
        color: "rgb(255, 255, 255)",
        textShadow: "0 0 15px rgb(255, 255, 255)",
      },
    },
    "@keyframes buttonBorderFlicker": {
      "2%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "3%": {
        border: "3px solid #6c57b8",
        boxShadow: "none",
      },
      "5%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "6%": {
        border: "3px solid #6c57b8",
        boxShadow: "none",
      },
      "7%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "9%": {
        border: "3px solid #6c57b8",
        boxShadow: "none",
      },
      "13%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "16%": {
        border: "3px solid #6c57b8",
        boxShadow: "none",
      },
      "18%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "22%": {
        border: "3px solid #6c57b8",
        boxShadow: "none",
      },
      "34%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "36%": {
        border: "3px solid #6c57b8",
        boxShadow: "none",
      },
      "54%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
      "100%": {
        border: "3px solid #4120ba",
        boxShadow: "0 0 15px -1px #4120ba, 0 0 12px -1px #4120ba inset",
      },
    },
    "@keyframes leftArrow": {
      "0%": {
        left: -65,
      },
      "50%": {
        left: -45,
      },
      "100%": {
        left: -65,
      },
    },
    "@keyframes rightArrow": {
      "0%": {
        right: -60,
      },
      "50%": {
        right: -40,
      },
      "100%": {
        right: -60,
      },
    },
  }),
);

interface GameButtonProps {
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export const GameButtonPrimary = React.forwardRef<
  HTMLInputElement,
  GameButtonProps
>(({ children, disabled, onClick }: GameButtonProps, ref) => {
  const classes = useStyles();

  return (
    <Button
      fullWidth
      className={classes.button}
      onClick={onClick}
      disableFocusRipple
      disabled={disabled}
      buttonRef={ref}
    >
      {children}
    </Button>
  );
});

export const GameButtonSecondary = React.forwardRef<
  HTMLInputElement,
  GameButtonProps
>(({ children, disabled, onClick }: GameButtonProps, ref) => {
  const classes = useStyles();

  return (
    <Button
      fullWidth
      className={classes.buttonMeetDevConcierge}
      onClick={onClick}
      disableFocusRipple
      disabled={disabled}
      buttonRef={ref}
      autoFocus
    >
      {children}
    </Button>
  );
});
