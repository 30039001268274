import { useMediaQuery } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    neonBoard: {
      background: "rgba(0, 20, 32, 0.95)",
      boxSizing: "border-box",
      borderRadius: 10,
      fontFamily: "'Goldman', cursive",
      letterSpacing: 1,
      lineHeight: 1,
      fontWeight: 800,
      position: "absolute",
      fontSize: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
    neonBoardPink: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      color: "rgb(255, 14, 231)",
      textShadow: "2.4874px 2.4874px 24.874px rgb(255, 14, 231)",
      boxShadow:
        "2.4874px 2.4874px 24.874px rgb(255, 14, 231), inset 0px 4.9748px 24.874px rgb(255, 14, 231)",
      border: "3px solid rgb(255, 14, 231)",
      left: -40,
      top: 50,
      animation: "$flickerPink 6s linear infinite",
      [theme.breakpoints.down("sm")]: {
        top: 25,
        left: -30,
      },
      [theme.breakpoints.down("xs")]: {
        top: 20,
        left: -35,
      },
    },
    neonBoardGold: {
      padding: theme.spacing(2),
      color: "rgb(248, 184, 0)",
      textShadow: "1.63891px 1.63891px 16.3891px rgb(248, 184, 0)",
      boxShadow:
        "2.05254px 2.05254px 20.5254px rgb(248, 184, 0),inset 0px 4.10508px 20.5254px rgb(248, 184, 0)",
      border: "3px solid rgb(248, 184, 0)",
      right: -70,
      top: 40,
      animation: "$flickerGold 5s linear infinite",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        top: 20,
        right: -50,
      },
      [theme.breakpoints.down("xs")]: {
        top: 6,
        right: -30,
        width: 96,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    neonBoardBlue: {
      padding: theme.spacing(2),
      color: "rgb(48, 194, 255)",
      textShadow: "1.63891px 1.63891px 16.3891px rgb(48, 194, 255)",
      boxShadow:
        "2.05254px 2.05254px 20.5254px rgb(48, 194, 255),inset 0px 4.10508px 20.5254px rgb(48, 194, 255)",
      border: "3px solid rgb(48, 194, 255)",
      right: -100,
      top: 150,
      animation: "$flickerBlue 5s linear infinite",
      [theme.breakpoints.down("sm")]: {
        top: 95,
        right: -50,
      },
      [theme.breakpoints.down("xs")]: {
        top: 57,
        right: -30,
        width: 96,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },

    "@keyframes flickerPink": {
      "1%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
      "2%": {
        border: "3px solid rgb(229, 128, 219)",
        boxShadow: "none",
        color: "rgb(229, 128, 219)",
        textShadow: "none",
      },
      "3%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
      "4%": {
        border: "3px solid rgb(229, 128, 219)",
        boxShadow: "none",
        color: "rgb(229, 128, 219)",
        textShadow: "none",
      },
      "5%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
      "50%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
      "51%": {
        border: "3px solid rgb(229, 128, 219)",
        boxShadow: "none",
        color: "rgb(229, 128, 219)",
        textShadow: "none",
      },
      "52%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
      "53%": {
        border: "3px solid rgb(229, 128, 219)",
        boxShadow: "none",
        color: "rgb(229, 128, 219)",
        textShadow: "none",
      },
      "54%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
      "100%": {
        border: "3px solid rgb(255, 14, 231)",
        boxShadow:
          "0 0 15px -1px rgb(255, 14, 231), 0 0 12px -1px rgb(255, 14, 231) inset",
        color: "rgb(255, 14, 231)",
        textShadow: "0 0 15px rgb(255, 14, 231)",
      },
    },

    "@keyframes flickerGold": {
      "1%": {
        border: "3px solid rgb(248, 184, 0)",
        boxShadow:
          "0 0 15px -1px rgb(248, 184, 0), 0 0 12px -1px rgb(248, 184, 0) inset",
        color: "rgb(248, 184, 0)",
        textShadow: "0 0 15px rgb(248, 184, 0)",
      },
      "30%": {
        border: "3px solid rgb(248, 184, 0)",
        boxShadow:
          "0 0 15px -1px rgb(248, 184, 0), 0 0 12px -1px rgb(248, 184, 0) inset",
        color: "rgb(248, 184, 0)",
        textShadow: "0 0 15px rgb(248, 184, 0)",
      },
      "31%": {
        border: "3px solid rrgb(247, 212, 107)",
        boxShadow: "none",
        color: "rrgb(247, 212, 107)",
        textShadow: "none",
      },
      "32%": {
        border: "3px solid rgb(248, 184, 0)",
        boxShadow:
          "0 0 15px -1px rgb(248, 184, 0), 0 0 12px -1px rgb(248, 184, 0) inset",
        color: "rgb(248, 184, 0)",
        textShadow: "0 0 15px rgb(248, 184, 0)",
      },
      "33%": {
        border: "3px solid rrgb(247, 212, 107)",
        boxShadow: "none",
        color: "rrgb(247, 212, 107)",
        textShadow: "none",
      },
      "34%": {
        border: "3px solid rgb(248, 184, 0)",
        boxShadow:
          "0 0 15px -1px rgb(248, 184, 0), 0 0 12px -1px rgb(248, 184, 0) inset",
        color: "rgb(248, 184, 0)",
        textShadow: "0 0 15px rgb(248, 184, 0)",
      },
      "100%": {
        border: "3px solid rgb(248, 184, 0)",
        boxShadow:
          "0 0 15px -1px rgb(248, 184, 0), 0 0 12px -1px rgb(248, 184, 0) inset",
        color: "rgb(248, 184, 0)",
        textShadow: "0 0 15px rgb(248, 184, 0)",
      },
    },

    "@keyframes flickerBlue": {
      "1%": {
        border: "3px solid rgb(77, 184, 230)",
        boxShadow:
          "0 0 15px -1px rgb(77, 184, 230), 0 0 12px -1px rgb(77, 184, 230) inset",
        color: "rgb(77, 184, 230)",
        textShadow: "0 0 15px rgb(77, 184, 230)",
      },
      "2%": {
        border: "3px solid rgb(48, 194, 255)",
        boxShadow: "none",
        color: "rgb(48, 194, 255)",
        textShadow: "none",
      },
      "3%": {
        border: "3px solid rgb(77, 184, 230)",
        boxShadow:
          "0 0 15px -1px rgb(77, 184, 230), 0 0 12px -1px rgb(77, 184, 230) inset",
        color: "rgb(77, 184, 230)",
        textShadow: "0 0 15px rgb(77, 184, 230)",
      },
      "4%": {
        border: "3px solid rgb(48, 194, 255)",
        boxShadow: "none",
        color: "rgb(48, 194, 255)",
        textShadow: "none",
      },
      "5%": {
        border: "3px solid rgb(77, 184, 230)",
        boxShadow:
          "0 0 15px -1px rgb(77, 184, 230), 0 0 12px -1px rgb(77, 184, 230) inset",
        color: "rgb(77, 184, 230)",
        textShadow: "0 0 15px rgb(77, 184, 230)",
      },
      "6%": {
        border: "3px solid rgb(48, 194, 255)",
        boxShadow:
          "0 0 15px -1px rgb(48, 194, 255), 0 0 12px -1px rgb(48, 194, 255) inset",
        color: "rgb(48, 194, 255)",
        textShadow: "0 0 15px rgb(48, 194, 255)",
      },
      "100%": {
        border: "3px solid rgb(48, 194, 255)",
        boxShadow:
          "0 0 15px -1px rgb(48, 194, 255), 0 0 12px -1px rgb(48, 194, 255) inset",
        color: "rgb(48, 194, 255)",
        textShadow: "0 0 15px rgb(48, 194, 255)",
      },
    },
  }),
);

export function NeonBoardPink({ ...rest }) {
  const classes = useStyles();

  return (
    <div className={`${classes.neonBoard} ${classes.neonBoardPink}`} {...rest}>
      THE
      <br />
      FUN IS
      <br />
      BACK
    </div>
  );
}

export function NeonBoardGold({ ...rest }) {
  const classes = useStyles();

  return (
    <div className={`${classes.neonBoard} ${classes.neonBoardGold}`} {...rest}>
      EXTRA
      <br />
      POWER
    </div>
  );
}

export function NeonBoardBlue({ ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div className={`${classes.neonBoard} ${classes.neonBoardBlue}`} {...rest}>
      PLAY{isXs && <br />} THE
      <br /> CTO{isXs && <br />} GAME
      <br /> TO WIN
    </div>
  );
}
