import { Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerTitle: {
      color: theme.palette.secondary.contrastText,
      fontWeight: 700,
      fontSize: 21,
      lineHeight: 0,
      marginTop: 50,
      fontFamily: "Merriweather",
    },
    line: {
      backgroundColor: theme.palette.secondary.contrastText,
      height: 1,
      width: 110,
      marginTop: 23,
    },
  }),
);

export default function FooterTitle({ children }: { children: string }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" className={classes.footerTitle} gutterBottom>
        {children}
      </Typography>
      <div className={classes.line} />
    </>
  );
}
