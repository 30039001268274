import { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    fade: {
      backgroundColor: "#fff",
      opacity: 1,
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 9999,
      transition: "opacity 2s ease-out",
      pointerEvents: "none",
    },
  }),
);

export default function Fade() {
  const classes = useStyles();

  const [opacity, setOpacity] = useState(1);
  const [isDestroyed, setIsDestroyed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(0);
    }, 500);
    setTimeout(() => {
      setIsDestroyed(true);
    }, 2600);
  }, []);

  return isDestroyed ? null : (
    <div className={classes.fade} style={{ opacity }} />
  );
}
