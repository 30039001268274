import { Container, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TestimonialContent from "../components/TestimonialContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      paddingTop: 150,
      paddingBottom: 250,
    },
    title: {
      color: theme.palette.primary.main,
      fontFamily: "Merriweather Sans",
      textAlign: "center",
      fontWeight: 700,
      fontSize: 36,
    },
    subtitle: {
      color: theme.palette.secondary.dark,
      fontFamily: "Open Sans",
      maxWidth: 590,
      fontSize: 20,
      margin: "auto",
      marginTop: 25,
      textAlign: "center",
    },
    body: {
      background: "linear-gradient(180deg, #003146 0%, #6C43FF 100%)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginTop: 50,
      paddingBottom: 300,
    },
    content: {
      marginTop: -100,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      flexDirection: "column",
      background:
        "linear-gradient(180deg, #003146 0%, #533FD4 65.62%, #533FD4 100%)",
      paddingBottom: 250,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: 350,
      },
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 300,
      },
      "& > div": {
        marginTop: -100,
      },
    },
    text: {
      color: theme.palette.primary.contrastText,
      paddingTop: 35,
      "& *": {
        fontFamily: "Open Sans",
        fontWeight: 300,
      },
    },
    name: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: "22.26px",
    },
    role: {
      fontSize: 17,
      lineHeight: "18px",
    },
    description: {
      marginTop: 35,
      maxWidth: 714,
      fontSize: 20,
    },
    highlight: {
      fontWeight: 700,
      background: "#ffe600",
      color: "#002f43",
    },
  }),
);

export default function Testimonial() {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.header}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          className={classes.title}
        >
          Hear what our clients say about DevConcierge™
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.subtitle}
        >
          Grow your business keeping your core development team focused on your
          product, without getting the release hell.
        </Typography>
      </Container>
      <div className={classes.body}>
        <Container>
          <Container className={classes.content}>
            <TestimonialContent />
          </Container>
        </Container>
      </div>
    </div>
  );
}
