import { ReactNode } from "react";

import "../css/glitch.css";

interface GlitchTextProps {
  children: ReactNode;
  className?: string;
}

export default function GlitchText({
  children,
  className = "",
}: GlitchTextProps) {
  return (
    <p className={`glitch ${className}`}>
      <span aria-hidden="true">{children}</span>
      {children}
      <span aria-hidden="true">{children}</span>
    </p>
  );
}
