import { forwardRef, useContext, useRef, useState } from "react";
import { Dialog, Fade, Grid, Hidden, Slide } from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";

import GlitchText from "../components/GlitchText";
import FadeIn from "../components/Game/FadeIn";
import FadeInOut from "../components/Game/FadeInOut";
import GameBoardContainer from "../components/Game/GameBoardContainer";
import {
  GameButtonPrimary,
  GameButtonSecondary,
} from "../components/Game/GameButton";
import GameVideoContainer from "../components/Game/GameVideoContainer";
import CursorGreen from "../components/Game/CursorGreen";
import GameVideo from "../components/Game/GameVideo";
import {
  NeonBoardPink,
  NeonBoardGold,
  NeonBoardBlue,
} from "../components/Game/NeonBoards";
import {
  BoxesContainer,
  BoxCTO,
  BoxDevConcierge,
} from "../components/Game/Boxes";
import GameTitle from "../components/Game/GameTitle";
import Logos from "../components/Game/Logos";
import Footer from "../components/Game/Footer";
import Ellipsis from "../components/Game/Ellipsis";
import SkipButton from "../components/Game/SkipButton";
import { GameContext } from "../contexts/GameContext";

import background from "../assets/img/game/brick-bg.png";

import time from "../components/Game/AnimationTime.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      background: `url("${background}")`,
      height: "100%",
      width: "100vw",
      position: "relative",
      overflowX: "hidden",
      backgroundColor: "#00111d",
    },
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignIntens: "center",
      "& > div:first-child": {
        display: "flex",
        flexDirection: "column",
        alignItens: "center",
        justifyContent: "center",
      },
    },
    titleContainer: {
      paddingTop: 50,
    },
    animation: {
      position: "relative",
      width: "100%",
      "& > div": {
        opacity: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        margin: "auto",
        lineHeight: 1.5,
      },
    },
    question: {
      position: "absolute",
      fontFamily: "'Goldman', sans-serif",
      color: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      transition: "font-size 0.15s ease-in-out",
      fontSize: "2.1rem",
      maxWidth: 550,
      height: "100%",
      "& > div": {
        maxWidth: 550,
        "& button": {
          marginTop: theme.spacing(1),
        },
        "& button *": {
          letterSpacing: 5,
        },
      },
      "& p": {
        margin: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.2rem",
      },
    },
    loading: {
      zIndex: 10,
      fontSize: "3rem",
      textAlign: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
      },
    },
    buttonContainer: {
      position: "absolute",
      bottom: -74,
      width: "100%",
      maxHeight: 60,
      textAlign: "center",
      transform: "perspective(-10px) rotateX(-1.5deg)",
      zIndex: 1,
      "& button": {
        minWidth: 250,
      },
    },
    videoBoardContainer: {
      boxSizing: "border-box",
      position: "absolute",
      width: "100%",
      maxWidth: 1100,
    },
    videoBoard: {
      "& > div": {
        position: "relative",
      },
    },
    buttonMeetDevConciergeContainer: {
      position: "absolute",
      bottom: -72,
      width: 350,
      zIndex: 1,
    },
    logoContainer: {
      paddingBottom: 20,
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Game() {
  const classes = useStyles();

  const { isOpen, setIsOpen } = useContext(GameContext);

  const startButton = useRef<HTMLInputElement>(null);
  const meetDevConciergeButton = useRef<HTMLInputElement>(null);

  const theme = useTheme();

  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(true);
  const [presentationDisplay, setPresentationDisplay] = useState(true);
  const [videoDisplay, setVideoDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);

  const loaded = () => {
    setIsLoading(false);

    setTimeout(() => {
      setIsStartButtonDisabled(false);
      if (startButton.current) startButton.current.focus();
    }, time.buttonContainer - 4000);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenVideo = () => {
    setPresentationDisplay(false);
    setVideoDisplay(true);
  };

  const onEnd = () => {
    setTimeout(() => {
      if (meetDevConciergeButton.current)
        meetDevConciergeButton.current.focus();
    });
  };

  const startAnimation = () => {
    setIsAnimationStarted(true);
    setTimeout(() => {
      loaded();
    }, time.loading.delay + time.loading.duration + 1000);
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ height: "100%" }}
    >
      <div className={classes.body}>
        <Grid
          container
          alignItems="stretch"
          justify="center"
          direction="column"
          wrap="nowrap"
          className={`${classes.container} noselect`}
        >
          <Grid item xs={12} className={classes.titleContainer}>
            <GameTitle />
            <Grid container justify="center">
              <Grid
                item
                xs={12}
                sm={11}
                md={10}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: theme.spacing(4),
                  maxWidth: 1100,
                  width: "100%",
                }}
              >
                <Fade
                  in={presentationDisplay}
                  timeout={1000}
                  style={{
                    width: "100%",
                  }}
                >
                  <div>
                    <GameBoardContainer>
                      {!isAnimationStarted && (
                        <div className={classes.question}>
                          <p>We’ve made a nostalgic amusing video game ad.</p>
                          <p>Do you want to see it?</p>
                          <div
                            className={classes.buttonContainer}
                            style={{ bottom: -85 }}
                          >
                            <GameButtonSecondary onClick={startAnimation}>
                              YES, I LIKE FUN STUFF!
                            </GameButtonSecondary>
                            <GameButtonPrimary onClick={handleClose}>
                              NO, I WANT THE SERIOUS STUFF.
                            </GameButtonPrimary>
                          </div>
                        </div>
                      )}
                      {isAnimationStarted && (
                        <>
                          <div className={classes.animation}>
                            {/* loading */}
                            {isLoading && (
                              <FadeInOut
                                delay={time.loading.delay}
                                duration={time.loading.duration}
                              >
                                <GlitchText className={classes.loading}>
                                  LOADING
                                  <Ellipsis />
                                </GlitchText>
                              </FadeInOut>
                            )}

                            {/* Cursor green */}
                            <FadeIn delay={time.cursorGreen}>
                              <CursorGreen />
                            </FadeIn>

                            {/* Neon board pink */}
                            <FadeIn delay={time.neonPink}>
                              <NeonBoardPink />
                            </FadeIn>

                            {/* Neon gold */}
                            <FadeIn delay={time.neonGold}>
                              <NeonBoardGold />
                            </FadeIn>

                            {/* Neon blue */}
                            <FadeIn delay={time.neonBlue}>
                              <NeonBoardBlue />
                            </FadeIn>

                            {/* Boxes */}
                            <BoxesContainer>
                              <BoxCTO delay={time.boxCTO} />
                              <BoxDevConcierge delay={time.boxDevConcierge} />
                            </BoxesContainer>
                          </div>
                          {/* Press Start */}
                          <div
                            className={classes.buttonContainer}
                            style={{ maxWidth: 500 }}
                          >
                            <FadeIn delay={time.buttonContainer}>
                              <GameButtonPrimary
                                onClick={handleOpenVideo}
                                disabled={isStartButtonDisabled}
                                ref={startButton}
                              >
                                PR<span className="second">E</span>SS STA
                                <span className="first">R</span>T
                              </GameButtonPrimary>
                            </FadeIn>
                          </div>
                        </>
                      )}
                    </GameBoardContainer>
                  </div>
                </Fade>
                <div className={classes.videoBoardContainer}>
                  <Fade in={videoDisplay} timeout={1000}>
                    <Grid
                      container
                      justify="center"
                      wrap="nowrap"
                      className={classes.videoBoard}
                    >
                      <Grid item xs={1} sm={2}>
                        <Hidden smDown>
                          <NeonBoardPink
                            style={{
                              right: 30,
                              left: "auto",
                            }}
                          />
                        </Hidden>
                      </Grid>
                      <Grid item xs={10} sm={8}>
                        <GameVideoContainer>
                          {videoDisplay && <GameVideo onEnd={onEnd} />}
                          <div
                            className={classes.buttonMeetDevConciergeContainer}
                          >
                            <GameButtonSecondary
                              onClick={handleClose}
                              ref={meetDevConciergeButton}
                            >
                              MEET DEVCONCIERGE
                            </GameButtonSecondary>
                          </div>
                        </GameVideoContainer>
                      </Grid>
                      <Grid item xs={1} sm={2}>
                        <Hidden smDown>
                          <NeonBoardGold
                            style={{
                              left: 45,
                              right: "auto",
                            }}
                          />
                          <NeonBoardBlue
                            style={{
                              left: 45,
                              right: "auto",
                            }}
                          />
                        </Hidden>
                      </Grid>
                    </Grid>
                  </Fade>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.logoContainer}>
            <Logos />
          </Grid>
        </Grid>
        <Footer />
        <SkipButton onClick={handleClose} />
      </div>
    </Dialog>
  );
}
