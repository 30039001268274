import React from "react";
import { Fade } from "@material-ui/core";

interface FadeInOutProps {
  children: React.ReactElement;
  delay: number;
  duration: number;
}

export default function FadeInOut({
  children,
  delay,
  duration,
}: FadeInOutProps) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, delay);

    setTimeout(() => {
      setShow(false);
    }, delay + duration);
  }, []);

  return (
    <div style={{ opacity: 1 }}>
      <Fade in={show} timeout={1000}>
        <div>{children}</div>
      </Fade>
    </div>
  );
}
