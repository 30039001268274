import React from "react";
import { Fade } from "@material-ui/core";

interface FadeInProps {
  children: React.ReactElement;
  delay: number;
}

export default function FadeIn({ children, delay }: FadeInProps) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, delay);
  }, []);

  return (
    <div style={{ opacity: show ? 1 : 0 }}>
      <Fade in={show} timeout={1000}>
        {children}
      </Fade>
    </div>
  );
}
