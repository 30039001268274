import { useContext, useEffect, useRef, useState } from "react";
import { Grid, Grow, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { GameContext } from "../contexts/GameContext";
import useOnScreen from "../hooks/useOnScreen";

import FocusIcon from "../assets/img/header/focus-icon.svg";
import GrowthIcon from "../assets/img/header/growth-icon.svg";
import ManagementIcon from "../assets/img/header/management-icon.svg";
import TeamIcon from "../assets/img/header/team-icon.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      marginTop: 5,
      marginBottom: 100,
    },
    card: {
      background: "rgba(38, 30, 117, 0.8)",
      borderRadius: 15,
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      margin: "auto",
      borderWidth: 2,
      borderStyle: "solid",
      "& *": { fontFamily: "Open Sans" },
      "& > div": { padding: 25 },
    },
    icon: {
      width: 70,
      height: 70,
      backgroundColor: "white",
    },
    title: {
      fontSize: 20,
      fontWeight: "bold",
      marginTop: 10,
      marginBottom: 30,
    },
    text: {
      color: theme.palette.primary.contrastText,
      "& *": {
        marginTop: 15,
        fontSize: 16,
      },
    },
  }),
);

export default function HeaderCards() {
  const classes = useStyles();
  const cardsRef = useRef(null);
  const isVisible = useOnScreen(cardsRef);
  const [fade, setFade] = useState(false);

  const { isOpen } = useContext(GameContext);

  interface cardProps {
    icon: string;
    title: string;
    color: string;
    content: JSX.Element;
  }

  const cards: cardProps[] = [
    {
      icon: TeamIcon,
      title: "Team Effectiveness",
      color: "#40C7DB",
      content: (
        <>
          <Typography variant="subtitle1" component="p" gutterBottom>
            You need great engineers on your tech team, and you also need to
            maximize their effectiveness.
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom>
            Through our disciplined continual improvement approach,
            DevConcierge™ keeps each stage of your development process at peak
            effectiveness, from developer tools to security updates, from
            nightly builds and release management to automated testing.
          </Typography>
        </>
      ),
    },
    {
      icon: FocusIcon,
      title: "Product Focus",
      color: "#30C2FF",
      content: (
        <>
          <Typography variant="subtitle1" component="p" gutterBottom>
            Your tech team needs to focus on your product, without headaches or
            distractions.
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom>
            DevConcierge™ creates a great experience for your developers,
            eliminating distractions and waste, letting them focus on making
            your product great.
          </Typography>
        </>
      ),
    },
    {
      icon: GrowthIcon,
      title: "Maximizing Growth",
      color: "#38E867",
      content: (
        <>
          <Typography variant="subtitle1" component="p" gutterBottom>
            When the door opens for your startup to grow exponentially, you must
            move fast to seize the opportunity.
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom>
            DevConcierge™ builds technical understanding and strong
            relationships with a trusted partner, giving you more options to
            manage growth and grab your market-dominating position.
          </Typography>
        </>
      ),
    },
    {
      icon: ManagementIcon,
      title: "Cost & Risk Management",
      color: "#40DBC1",
      content: (
        <>
          <Typography variant="subtitle1" component="p" gutterBottom>
            Outsourcing helps manage your costs, but cost is about more than
            price. It is also driven by execution risk and effectiveness.
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom>
            DevConcierge™ gives tech companies a low-risk, high-impact
            outsourcing service that makes your tech team more effective.
          </Typography>
        </>
      ),
    },
  ];

  useEffect(() => {
    !isOpen && isVisible && setFade(true);
  }, [isOpen, isVisible]);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="stretch"
      spacing={2}
      className={classes.cardsContainer}
      innerRef={cardsRef}
    >
      {cards.map((card, i) => (
        <Grow
          key={i}
          in={fade}
          style={{ transformOrigin: "0 0 0" }}
          {...(fade ? { timeout: (i + 1) * 500 + 500 } : {})}
        >
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.card} style={{ borderColor: card.color }}>
              <div>
                <div
                  className={classes.icon}
                  style={{ background: `url("${card.icon}") no-repeat` }}
                />
                <Typography
                  variant="h1"
                  component="h1"
                  gutterBottom
                  className={classes.title}
                  style={{ color: card.color }}
                >
                  {card.title}
                </Typography>
                <div className={classes.text}>{card.content}</div>
              </div>
            </div>
          </Grid>
        </Grow>
      ))}
    </Grid>
  );
}
